<template>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="密碼" prop="password" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="確認密码" prop="checkPassword" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.checkPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
</template>
<script>
export default {
  name: 'ChangePassword',
  created () {
  },
  data() {
          var validatePass = (rule, value, callback) => {
            if (value === '' && this.form.id == null) {
              callback(new Error('請輸入密碼'));
            } else {
              if (this.form.checkPassword !== '') {
                this.$refs.form.validateField('checkPassword');
              }
              callback();
            }
          };
          var validatePass2 = (rule, value, callback) => {
            if (value === '' && this.form.id == null) {
              callback(new Error('請再次輸入密碼'));
            } else if (value !== this.form.password) {
              callback(new Error('兩次輸入密碼不一致!'));
            } else {
              callback();
            }
          };
        return {
          form: {
            password: '',
            checkPassword:'',
          },
          dialogFormVisible: false,
          formLabelWidth: '120px',
          rules: {
            password: [
              { validator: validatePass, trigger: 'blur' },
              
            ],
            checkPassword: [
              { validator: validatePass2, trigger: 'blur' }
            ],
          },
        }
  },
  methods:{
    clearForm() {
      this.form.password='';
      this.form.checkPassword='';
    },
    submit() {
        console.log('submit');
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$store.dispatch('auth/updatePassword',this.form,{root: true}).then(res=>{
                this.$vuedals.close();
            })
          } else {
            return false;
          }
        });
    },
  },
}
</script>